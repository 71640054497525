import { useNavigate } from "react-router-dom";
import { useApps } from "../../hooks/use-apps";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { CreateForm } from "../../components/CreateForm";
import { useTranslation } from "react-i18next";
import { toDateString } from "../../utils/date";

export function AppListView() {
  const { apps } = useApps();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!apps.data) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  return (
    <main className="p-4 sm:p-6">
      <h2 className="text-2xl font-bold mb-4">{t("apps.list.title")}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-8">
        {apps.data.length === 0 && (
          <div className="text-center text-gray-500">
            {t("apps.list.no_apps")}
          </div>
        )}
        {apps.data.map((app) => (
          <Card key={app.id}>
            <CardHeader>
              <CardTitle>{app.app_name}</CardTitle>
              <CardDescription>
                {t("apps.list.created_at", {
                  val: toDateString(app.created_at),
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    },
                  },
                })}
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                variant="outline"
                onClick={() => {
                  navigate(`/apps/${app.id}/overview`);
                }}
              >
                {t("apps.list.view_detail")}
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>

      <Separator className="my-8" />

      <CreateForm />
    </main>
  );
}
